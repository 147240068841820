import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { PureComponent } from "react";
import { Link, navigate } from 'gatsby'
import { FormFeedback, FormGroup, Form, Input, Row, Col, Button } from 'reactstrap'
import { handleLogin, isLoggedIn } from '../services/auth'
import Amplify, { Auth, Cache, Hub, Logger, API } from "aws-amplify";
import { CODE_SIGN_IN, CODE_COGNITO } from '../components/authcode'

import signupLogo from '../images/signup-logo.png'
import formStyles from '../components/styles/form.module.css'
import "../components/styles/layout.css";
import qs from 'query-string'

export default class Login extends PureComponent {
    constructor(props) {
        super(props);

        this.queryData = qs.parse(props.location, { ignoreQueryPrefix: true });

        this.state = {
            email: "",
            password: "",
            attempt: 4,
            respError: true,
            message: "",
            isLoading: false
        };
    }

    // Handle value change for the input and save it into the state
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }


    handleSubmit = async (event) => {
        Cache.setItem("matrigger", 0)
        Cache.setItem("ma", 0)
        Cache.setItem("otpToken", "")
        event.preventDefault()
        this.setState({
            isLoading: true
        })
        await handleLogin(this.state)
            .then(async (respHandler) => {
                if (this.state.attempt > 0) {
                    switch (respHandler.code) {
                        case CODE_SIGN_IN.USER_NOT_FOUND:
                            this.setState({
                                message: "signup",
                                respError: false

                            })
                            break

                        case CODE_COGNITO.PROCESS_INVALID:
                            this.setState({
                                message: respHandler.message,
                                respError: false,

                            })
                            break

                        case CODE_SIGN_IN.USER_INCORRECT_PASSWORD:
                            this.setState({
                                message: respHandler.message === "Access Token has been revoked" ? ("You are currently logged in from another browser") : ("Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below."),
                                respError: false,
                                attempt: this.state.attempt - 1
                                //Incorrect password, You have " + this.state.attempt + " attempt(s) remaining

                            })
                            break

                        case CODE_SIGN_IN.USER_NOT_VERIFIED:
                            this.setState({
                                message: "This email is not yet verified. Please check your email for the verification link.",
                                respError: false,

                            })
                            break

                        case false:
                            console.log('Something went wrong!')
                            navigate('/')
                            break

                        case CODE_SIGN_IN.USER_BLOCKED:
                            this.setState({
                                message: respHandler.message,
                                respError: false,

                            })
                            break

                        case CODE_SIGN_IN.USER_LAMBDA_BLOCKED:
                            this.setState({
                                message: "Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below.",
                                //message: "Your account has been temporarily blocked due to a number of incorrect attempts. Please unblock your account by using the Forgot Password / Resend Activation feature below.",
                                respError: false,

                            })
                            break

                        default:
                            if (!this.queryData.referrer) {
                                window.location.replace('/')
                            } else {
                                window.location.replace(decodeURIComponent(this.queryData.referrer))
                            }
                            break
                    }
                } else {

                    this.setState({
                        message: "Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below.",
                        //message: "Your account has been temporarily blocked due to a number of incorrect attempts. Please unblock your account by using the Forgot Password / Resend Activation feature below.",
                        respError: false,
                    })

                }

                this.setState({
                    isLoading: false
                })
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                })
                console.log(err)
            });
    }

    UNSAFE_componentWillUpdate() {
        if (isLoggedIn()) {
            if (!this.queryData.referrer) {
                window.location.replace('/')
            } else {
                window.location.replace(decodeURIComponent(this.queryData.referrer))
            }
        }
    }

    componentDidMount() {
        if (isLoggedIn()) {
            if (!this.queryData.referrer) {
                window.location.replace('/')
            } else {
                window.location.replace(decodeURIComponent(this.queryData.referrer))
            }
        }
        this.forceUpdate()
    }

    render() {
        return (

            <Row >
                <Col
                    className={[formStyles.formContainer, formStyles.login, "row"].join(' ')}
                    sm={12} xs={12}
                    md={{
                        size: 7
                    }}
                >
                    <Form
                        style={{
                            width: "90%"
                        }}
                        onSubmit={this.handleSubmit}>
                        <h1 style={{
                            fontWeight: 'normal'
                        }}>
                            Sign In
                        </h1>
                        <FormGroup>
                            <Input
                                id="email"
                                autoFocus
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                className={formStyles.inputStyle}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="password"
                                type="password"
                                name="password"
                                required
                                autoComplete="off"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                className={formStyles.inputStyle}
                                invalid={!this.state.respError}
                            />
                            <FormFeedback invalid={!this.state.respError ? '' : undefined}>
                                {this.state.message === 'signup' ? (
                                    <p>
                                        Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below.
                                    </p>
                                    //Sorry, we are not able to log you in at this moment. If you have not registered, click <a href="/signup/"> here </a> to sign up now
                                ) : this.state.message}
                            </FormFeedback>
                        </FormGroup>

                        <div className="">
                            <Link to="/forgot" style={{
                                fontSize: '0.8rem',
                                color: '#007bff'
                            }}>
                                Forgot Password/Resend Activation Link
                            </Link>

                            <Button
                                style={{ backgroundColor: "rgb(51,81,166)" }}
                                className={[formStyles.buttonStyles, "float-right", "d-none", "d-md-block"].join(' ')}
                                disabled={this.state.isLoading ? true : undefined}
                                color={this.state.isLoading ? 'secondary' : 'primary'}
                            >
                                {this.state.isLoading ? "LOADING..." : "SIGN IN"}
                            </Button>

                            <Button
                                style={{ backgroundColor: "rgb(51,81,166)" }}
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')}
                                block
                                disabled={this.state.isLoading ? true : undefined}
                                color={this.state.isLoading ? 'secondary' : 'primary'}

                            >
                                {this.state.isLoading ? "LOADING..." : "SIGN IN"}
                            </Button>
                        </div>
                    </Form>
                </Col>

                <br className="d-md-none" />
                <Col
                    className={formStyles.signUpBox}
                    sm={{
                        size: 12
                    }}
                    md={{
                        size: 5
                    }}>
                    <img src={signupLogo} alt="Nets Developer Portal"
                        style={{
                            maxWidth: 110,
                            marginBottom: 'unset'
                        }} />
                    <h2 style={{
                        fontWeight: 'bolder'
                    }}>
                        Not a developer yet?
                    </h2>

                    <ul className={formStyles.listStyle}>
                        <li><i className="fas fa-chevron-right"></i> Access API Library</li>
                        <li><i className="fas fa-chevron-right"></i> Participate in Forum</li>
                        <li><i className="fas fa-chevron-right"></i> Create API Key</li>
                        <li><i className="fas fa-chevron-right"></i> and more</li>
                    </ul>
                    {/* Desktop Display */}
                    <Button
                        style={{
                            padding: "5px 20px"
                        }}
                        className={[formStyles.buttonStyles, formStyles.buttonSignUp, "float-left", "d-none", "d-md-block",].join(' ')}
                        onClick={
                            event => {
                                event.preventDefault();
                                navigate('/signup/');
                            }
                        }
                    >
                        SIGN UP NOW
                    </Button>
                    {/* Mobile Display */}
                    <Button
                        className={[formStyles.buttonStyles, formStyles.buttonSignUp, "d-md-none"].join(' ')} block
                        onClick={
                            event => {
                                event.preventDefault();
                                navigate('/signup/');
                            }
                        }
                    >
                        SIGN UP NOW
                    </Button>
                </Col>

            </Row>
        );
    }
}